<template>
  <div>
    <v-row no-gutters justify="center" align="center">
      <v-col cols="4">
        <div @click.stop="dialog=true">
          <slot/>
        </div>
      </v-col>
      <v-col>
        <v-slider dense hide-details
                  color="primary darken-3"
                  :name="valueName"
                  :min="min"
                  :max="max"
                  step="1"
                  @input="value = Math.round($event)"
                  :value="value"
                  :thumb-label="thumbLabel"
                  @change="$emit('value-changed', value)"/>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="400px">
      <app-dialog @close="dialog=false">
        <template #title>Set {{ valueName }} Manually</template>
        <v-row>
          <v-col :cols="12">Insert new {{ valueName }}</v-col>
          <v-col :cols="12">
            <v-text-field dense
                          v-model="value"
                          type="number"
                          @focusout="$emit('value-changed', value)"/>
          </v-col>
          <v-col>
            <v-btn block color="secondary" @click="dialog=false">
              Close
            </v-btn>
          </v-col>
        </v-row>
      </app-dialog>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'AppSlider',

    components: {
      AppDialog: () => import(/* webpackChunkName: "app-main" */ 'framework/ui/components/AppDialog'),
    },

    props: {
      initialValue: { type: Number },
      valueName:    { type: String },
      min:          { type: Number },
      max:          { type: Number },
      thumbLabel:   { default: true },
    },

    data() {
      return {
        value:  null,
        dialog: false,
      }
    },

    mounted() {
      this.value = this.initialValue
    },
  }
</script>
