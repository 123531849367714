export const AVAILABLE_DRUM_SOUNDS = [
  'clap',
  'hihat-closed',
  'hihat-open',
  'kick',
  'ride',
  'snare',
  'tom-high',
  'tom-low',
  'tom-mid',
]

export const AVAILABLE_SYNTHS = [
  'Synth',
  'AMSynth',
  'FMSynth',
  'MonoSynth',
]

export const AVAILABLE_INSTRUMENTS = [
  'bass-electric',
  'basson',
  'cello',
  'clarinet',
  'contrabass',
  'flute',
  'french-horn',
  'guitar-acoustic',
  'guitar-electric',
  'guitar-nylon',
  'harmonium',
  'organ',
  'piano',
  'saxophone',
  'trumpet',
  'tuba',
  'violin',
  'xylophone',
]

export const NOTE_DURATIONS      = [1, 2, 4, 8, 16, 32]
export const TIME_SIG_NUMERATORS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

export const DEFAULT_PLAYER = 'default'
export const DRUMS_PLAYER   = 'drums'
export const PLAYER_2       = 'p2'
export const PLAYER_3       = 'p3'
export const PLAYER_4       = 'p4'
export const PLAYER_5       = 'p5'
export const PLAYER_6       = 'p6'
export const PLAYER_7       = 'p7'
export const PLAYER_8       = 'p8'
export const PLAYER_9       = 'p9'
export const PLAYER_10      = 'p10'
export const PLAYER_11      = 'p11'
export const PLAYER_12      = 'p12'
export const PLAYER_13      = 'p13'
export const PLAYER_14      = 'p14'

export const APP_PLAYER_NAMES = [
  DEFAULT_PLAYER,
  PLAYER_2,
  PLAYER_3,
  PLAYER_4,
  PLAYER_5,
  PLAYER_6,
  PLAYER_7,
  PLAYER_8,
  PLAYER_9,
  PLAYER_10,
  PLAYER_11,
  PLAYER_12,
  PLAYER_13,
  PLAYER_14,
]

export const DEFAULT_PITCH_CLASS = 'default'
export const DEFAULT_OCTAVE      = 'default'

export const HARMONY_TYPES = ['Slow', 'Medium', 'Fast']
