<template>
  <div class="text-capitalize">
    <div class="text-center pb-12">
      <span :class="`display-1 font-weight-bold`" style="border-bottom: solid .5px">
        Metronome
      </span>
    </div>
    <span class="font-weight-bold">
      Time Signature
    </span>
    <v-row justify="center">
      <v-col cols="5" sm="3">
        <v-select dense rounded outlined flat hide-details
                  v-model="numerator"
                  color="white"
                  item-color="accent"
                  :items="TIME_SIG_NUMERATORS"
                  @change="restart"/>
      </v-col>
      <v-col cols="1">
        <span class="font-weight-black title">
          /
        </span>
      </v-col>
      <v-col cols="5" sm="3">
        <v-select dense rounded outlined flat hide-details
                  v-model="denominator"
                  color="white"
                  item-color="accent"
                  :items="NOTE_DURATIONS"
                  @change="restart"/>
      </v-col>
    </v-row>
    <div class="font-weight-bold"
         style="font-size: 25vh; cursor: pointer"
         @click="getIsTransportStarted ? stopMetronome() : startMetronome()">
      <app-divider/>
      <span v-if="shouldShowHint" class="headline font-weight-black error--text">
        Click Me...
      </span>
      {{ getBeat }}
      <app-divider/>
    </div>
    <div class="py-6">
      <app-slider thumb-label="always" value-name="BPM" :initial-value="getTransportBPM" :min="40" :max="250"
                  @value-changed="bpmUpdate">
        <v-btn :small="$vuetify.breakpoint.mdAndDown"
               color="secondary">
          BPM: {{ getTransportBPM }}
        </v-btn>
      </app-slider>
    </div>
    <v-row align="center">
      <v-col cols="4">
        Sound
      </v-col>
      <v-col cols="8" class="text-capitalize">
        <v-select dense rounded outlined flat hide-details
                  v-model="downBeat"
                  color="white"
                  style="max-width: 50vw; display: inline-flex"
                  :items="AVAILABLE_DRUM_SOUNDS"
                  @change="restart"/>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="4">
        <v-checkbox hide-details v-model="isFirstDownBeat" label="Accent"
                    color="success"
                    style="display: inline-block"
                    @change="restart"/>
      </v-col>
      <v-col cols="8" v-if="isFirstDownBeat">
        <v-select dense rounded outlined flat hide-details
                  item-color="accent"
                  v-model="firstDownBeat"
                  color="white"
                  style="max-width: 50vw; display: inline-flex"
                  :items="AVAILABLE_DRUM_SOUNDS"
                  @change="restart"/>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="4">
        <v-checkbox hide-details v-model="isUpBeat" label="Upbeat"
                    :disabled="isTriplets"
                    color="success"
                    style="display: inline-flex"
                    @change="restart"/>
      </v-col>
      <v-col cols="8" v-if="isUpBeat">
        <v-select dense rounded outlined flat hide-details
                  v-model="upBeat"
                  item-color="accent"
                  color="white"
                  style="max-width: 50vw; display: inline-flex"
                  :items="AVAILABLE_DRUM_SOUNDS"
                  @change="restart"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-checkbox hide-details v-model="isTriplets" label="Triplets"
                    color="success"
                    style="display: inline-block"
                    @change="restart"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters }                                     from 'vuex'
import { Transport }                                                  from 'tone'
import { TIME_SIG_NUMERATORS, AVAILABLE_DRUM_SOUNDS, NOTE_DURATIONS } from 'framework/resources/framework-constants'
import AppSlider                                                      from 'framework/ui/components/AppSlider'
import { setTransportTimeSignature, stopTransport }                   from 'framework/helpers/tone-helpers'
import AppDivider                                                     from 'framework/ui/components/AppDivider'

export default {
  name: 'MetronomeClock',

  head: {
    title: function() {
      return {
        inner: 'Metronome'
      }
    }
  },

  components: { AppDivider, AppSlider },

  async created() {
    Transport.loopEnd = '1m'
    Transport.loop    = false
  },

  destroyed() {
    setTransportTimeSignature(4)
    stopTransport()
  },

  data() {
    return {
      shouldShowHint:  true,
      firstDownBeat:   'kick',
      isFirstDownBeat: true,
      downBeat:        'hihat-closed',
      isUpBeat:        false,
      upBeat:          'ride',
      numerator:       4,
      denominator:     4,
      isTriplets:      false,
      AVAILABLE_DRUM_SOUNDS,
      NOTE_DURATIONS,
      TIME_SIG_NUMERATORS
    }
  },

  computed: {
    ...mapGetters('transport', ['getTransportBPM', 'getIsTransportStarted']),
    ...mapGetters('metronome', ['getBeat']),

    isPlaying: function() {
      return this.getIsTransportStarted ? 'Stop' : 'Start'
    },

    isPlayingIcon: function() {
      return this.getIsTransportStarted ? 'stop' : 'play-arrow'
    }
  },

  methods: {
    ...mapActions('transport', ['stopPlayback', 'startPlayback', 'setBPM']),
    ...mapActions('metronome', ['scheduleMetronome', 'setBeat']),

    async startMetronome() {
      if(this.shouldShowHint) {
        this.shouldShowHint = false
      }
      const config = {
        numerator:     this.numerator,
        denominator:   this.denominator,
        firstDownBeat: this.isFirstDownBeat ? this.firstDownBeat : this.downBeat,
        downBeat:      this.downBeat,
        upBeat:        this.isUpBeat ? this.upBeat : null,
        isTriplets:    this.isTriplets
      }
      await this.scheduleMetronome(config)
      this.startPlayback()
    },

    async stopMetronome() {
      this.stopPlayback()
      setTimeout(() => {
        this.setBeat({ value: 0 })
      }, 50)
    },

    restart() {
      if(this.getIsTransportStarted) {
        this.stopMetronome()
        setTimeout(() => this.startMetronome(), 50)
      }
    },

    bpmUpdate(val) {
      this.setBPM(val)
      this.restart()
    }
  }
}
</script>
