import { context, Transport } from 'tone'

export function setTransportBPM(value) {
  Transport.bpm.rampTo(value)
}

export function setTransportLooping(value) {
  Transport.loop = value
}

export function isTransportStarted() {
  return Transport.state === 'started'
}

export function startTransport(start = 0) {
  if(context.state !== 'running') {
    context.resume()
  }
  Transport.start('+0.1', start)
}

export function stopTransport() {
  Transport.stop()
}

export function setTransportTimeSignature(timeSig) {
  Transport.timeSignature = timeSig
}

export function setTransportLoopPoints({ start, end }) {
  Transport.setLoopPoints(start, end)
}

export function getCurrentTransportTime() {
  return Transport.getSecondsAtTime().toString()
}
